import React from 'react'

const Footer = () => (
  <footer className="footer">
    <small>
      &copy; {new Date().getFullYear()} Pradeep Suryakant Mhapsekar. Website by{' '}
      <a href="https://apherio.com" target="_blank" rel="noopener noreferrer">
        Sahil Mhapsekar
      </a>
      .
    </small>
  </footer>
)

export default Footer
