import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from './header'
import Footer from './footer'

import '../scss/layout.scss'
import bannerImg from '../images/pradeep_mhapsekar.jpeg'

require('typeface-lato')

class Layout extends React.Component {
  render() {
    const { children, isHome } = this.props

    return (
      <>
        <Helmet
          title="Pradeep Mhapsekar"
          meta={[
            {
              name: 'description',
              content:
                'Pradeep Mhapsekar is mumbai based renowned cartoonist.He is known for this witty political cartoons.His work encompasses colours, lines, figures and words. ',
            },
            {
              name: 'keywords',
              content:
                'Mumbai painter, Mumbai illustrator, India painter, Marathi illustrator, Marathi artist, Marathi painter, Indian Cartonist, Award winning book writer,Marathi Author',
            },
            // { name: 'google-site-verification',  content: 'PuWXzRCsyekukWviRC8ttBgo70TTY5YCJW9jeqkus-Y' },
            { name: 'og:image', content: bannerImg },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <Header isHome={isHome} />
        <main>{children}</main>
        <Footer />
      </>
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
